/*
Workaround for switching from the react-rails gem to vite-rails in PM-25993
*/

import * as ActionCable from '@rails/actioncable';
window.ActionCable = ActionCable;

import cableSetup from './components/cable.ts';
cableSetup();

import React from 'react';
window.React = React;

import ReactDOM from 'react-dom';
window.ReactDOM = ReactDOM;

import DocumentsModal from './documents_modal.js.jsx';
window.DocumentsModal = DocumentsModal;

import NotificationsPanel from './notifications_panel.js.jsx';
window.NotificationsPanel = NotificationsPanel;

import EditPropertyUnitsModal from './edit_property_units_modal.js.jsx';
window.EditPropertyUnitsModal = EditPropertyUnitsModal;

import MoveTransactionsModal from './move_transactions_modal.js.jsx';
window.MoveTransactionsModal = MoveTransactionsModal;

import PrintQueueModal from './print_queue_modal.js.jsx';
window.PrintQueueModal = PrintQueueModal;

import ScrollableList from './scrollable_list.js.jsx';
window.ScrollableList = ScrollableList;

import anchorme from 'anchorme';
window.anchorme = anchorme;

import * as pdfjsLib from 'pdfjs-dist';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.js?url';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
window.pdfjsLib = pdfjsLib;
window.pdfjsViewer = pdfjsViewer;

import { Dropzone } from 'dropzone';
window.Dropzone = Dropzone;

import * as _ from 'underscore';
window._ = _;

import SignaturePad from 'signature_pad';
window.SignaturePad = SignaturePad;

import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.min.css';
window.Handsontable = Handsontable;

import Swiper from 'swiper/bundle';
window.Swiper = Swiper;
